import { RouteRecordRaw } from "vue-router";

const accessesRoute: RouteRecordRaw = {
  path: "/accesses",
  name: "accesses",
  component: () => import("../views/accessesComponent.vue"),
};

export default accessesRoute;

