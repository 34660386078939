import { RouteRecordRaw } from "vue-router";

const rolesRoute: RouteRecordRaw = {
  path: "/roles",
  name: "roles",
  component: () => import("../views/rolesComponent.vue"),
  
};

export default rolesRoute;
