import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "alt", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.hasSrc)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.avatarClassNames)
      }, [
        (_ctx.hasSrc)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.src,
              alt: _ctx.alt,
              title: _ctx.title,
              class: "avatar-img"
            }, null, 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(_ctx.textClassNames)
            }, _toDisplayString(_ctx.fullName), 3))
      ], 2))
    : _createCommentVNode("", true)
}