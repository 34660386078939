
import { useAuthStore } from "./Stores/auth";
import Sidebar from "@/components/Organisms/OrganismSidebar.vue";
import Navbar from "@/components/Molecules/MoleculNavbar.vue";
import { computed } from "vue";
import axios from 'axios';
import { useRoute, useRouter } from "vue-router";
import { 
logout,
} from '@/services/authService.api';


import { onMounted } from 'vue';
export default {
  name: "App",
  components: {
    Sidebar,
    Navbar,
  }, 
  setup() {
    const router = useRouter();
    const route = useRoute();
    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated); 
    // const isLoginPage = computed(() => route.path === "/login");
    // if (!authStore.isAuthenticated) {
    //   router.push("/login");
    // }




    onMounted(() => {
      const access_token = localStorage.getItem('access_token');
      const isRegisterPage = route.path === "/register";
      const isRecevoirFormPage = route.path.startsWith("/recevoirform");

      if (access_token) {
        const user = JSON.parse(localStorage.getItem('user')!);
        authStore.authUser({
          user: user,
          access_token: access_token,
          refresh_token: localStorage.getItem('refresh_token')!,
          expires_in: localStorage.getItem('expires_in')!,
        });
      } else if (!isRegisterPage && !isRecevoirFormPage) {
        router.push("/login");
      }
    });
    const logout = async () => {
      try {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        authStore.logout();

        router.push({ name: 'login' });
        location.reload();
      } catch (error) {
        console.error("Error during logout:", error);
      }
    };
    /* const logout = async () => {
      try {
         await logout();
        //console.log(response.data.msg);
        router.push({ name: 'login' });
        location.reload();
      } catch (error) {
        console.error("Error during logout:", error);
      }
    }; */
    return {
    isAuthenticated,
    //isLoginPage,
      logout,
    
    };
  },
 
};
