// eslint-disable-next-line
/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import loginRoute from "./login";
import forgetPassRoute from "./forgetPass";
import resetPassRoute from "./resetPass";
import ressourceRoute from "./ressource";
import posteRoute from "./poste";
import profileRoute from "./profile";
import { addUser, editUser } from './addUser';
import archiveRoute from "./archiveUser";
import addPosteRoute from "./addPoste";
import editRoute from "./editUser";
import editPosteRoute from "./editPoste";
import agendaRoute from "./agenda";
import accessesRoute from "./accesses";
import { addAccess, editAccess } from './addAccess';
import rolesRoute from "./roles";
import { addRole, editRole } from './addRole';
import { useAuthStore } from "@/Stores/auth";
import evaluationformRoute from "./evaluationForm";
import recevoirformRoute from "./recevoirform";
import filledformRoute from "./filledForm";
import ressourcedetailRoute from "./userDetails";
import dashbordRoute from "./dashbord";
import configRoute from "./config";
import notificationRoute from "./notification";
import registerRoute from "./register";


const routes: Array<RouteRecordRaw> = [
  loginRoute,
  forgetPassRoute,
  resetPassRoute,
  ressourceRoute,
  addUser,
  editUser,
  archiveRoute,
  editRoute,
  posteRoute,
  addPosteRoute,
  editPosteRoute,
  agendaRoute,
  accessesRoute,
  addAccess,
  editAccess,
  rolesRoute,
  addRole,
  editRole,
  profileRoute,
  evaluationformRoute,
  recevoirformRoute,
  filledformRoute,
  ressourcedetailRoute,
  dashbordRoute,
  configRoute,
  notificationRoute,
  registerRoute

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeResolve((to, from, next) => {
  const authStore = useAuthStore();
  if (to.matched.some((m) => m.meta.requiresAuth) && !authStore.access_token) {
    next({
      name: "login",
    });
  } else if (
    to.matched.some((m) => m.meta.authorize)
  ) {
    next({
      name: "/",
    });
  } else {
    next();
  }
});

export default router;
