// src/services/userService.js
import  axiosClient from "@/Stores/axios";

const createUser = (user:any) => axiosClient.post("/users", user);
const getAllUsers = () => axiosClient.get("/users");
const updateUserInfo = (user:any) => axiosClient.put(`/users/${user.id}`, user);
const deleteUser = (id:any) => axiosClient.delete(`/users/${id}`);
const getUserById = (id:any) => axiosClient.get(`/users/${id}`);
const getAllUsersByParams = (data: any) => axiosClient.get("/users/getAllUsersByParams",data);
const getAllUsersByColumn = (column: any) => axiosClient.get("/users/getAllUsersByColumn/{column}",column);
const updateUserStatus = (userId: string | number, status: number) => {
  return axiosClient.put(`/users/${userId}/update-status`, { status });
};
const profile = () => axiosClient.get("/profile");
const getUserFilledForms = (userId: any) => axiosClient.get(`/users/${userId}/filled-forms`);
const archiveUser = (id:any) => axiosClient.put(`/user/archive/${id}`);
const getArchivedUsers = () => axiosClient.get("/users/archived");
const unarchiveUser = (id:any) => axiosClient.put(`/user/unarchive/${id}`);
const uploadFile = (formData: FormData) => {
    return axiosClient.post("/upload", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

export {
  getUserById,
  createUser,
  getAllUsers,
  updateUserInfo,
  getAllUsersByColumn,
  getAllUsersByParams,
  deleteUser,
  profile,
  archiveUser,
  getArchivedUsers,
  unarchiveUser,
  uploadFile,
  updateUserStatus,
  getUserFilledForms

};
