import  axiosClient from "@/Stores/axios";

const sendNotification = (notificationData:any) => axiosClient.post("/send-notification", notificationData);

const getAllNotifications = () => axiosClient.get("/notifications");

const getLatestNotifications = () => axiosClient.get("/notifications/latest");

const getUnreadCount = () => axiosClient.get("/notifications/unread-count");

const dismissNotification = (id:any) => axiosClient.post(`/notifications/dismiss/${id}`);

export {
  sendNotification,
  getAllNotifications,
  getLatestNotifications,
  getUnreadCount,
  dismissNotification
};
