import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { class: "page-content" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.isAuthenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Sidebar),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Navbar),
            _createVNode(_component_router_view)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_router_view)
        ]))
  ]))
}