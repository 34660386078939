import { RouteRecordRaw } from "vue-router";
const addUser: RouteRecordRaw = {
  path: "/add-user",
  name: "add-user",
  component: () => import("../views/AddView.vue"),
};

const editUser: RouteRecordRaw = {
  path: "/edit-user/:id",
  name: "edit-user",
  component: () => import("../views/AddView.vue"),
  props: true
};

export { addUser, editUser };
