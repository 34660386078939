
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "MoleculButton",
  props: {
    text: {
      type: String,
       default: "Default",
    },
    className: {
      type: String,
    },
    type: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "fa-solid fa-arrow-right",
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const classNames = computed(() => {
      const classNames = ["btn"];
      if (props.type) {
        classNames.push(props.type);
      }
      if (props.className) {
        classNames.push(props.className);
      }
      return classNames.join(" ");
    });

    const classNamesReverse = computed(() => {
      const classNames = ["btn", "flex-row-reverse"];
      if (props.type) {
        classNames.push(props.type);
      }
      if (props.className) {
        classNames.push(props.className);
      }
      return classNames.join(" ");
    });

    const classIcons = computed(() => {
      const classIcons = [];
      if (props.iconLeft) {
        classIcons.push("mr-12");
      } else if (props.iconRight) {
        classIcons.push("ml-12");
      }
      return classIcons.join(" ");
    });

    const handleClick = () => {
      emit("clicked");
    };

    return {
      classNames,
      classNamesReverse,
      classIcons,
      handleClick,
    };
  },
});
