import  axiosClient from "@/Stores/axios";
const login = (credentials:any) => axiosClient.post("/login", credentials);
const logout = () => axiosClient.post("/logout");
const register = (userData:any) => axiosClient.post("/register", userData);
const forgotPassword = (email:any) => axiosClient.post("/forgot-password", { email });
const resetPassword = (resetData:any) => axiosClient.post("/reset-password", resetData);
export {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword
};