import { defineStore } from 'pinia';
import { getUnreadCount, getAllNotifications, dismissNotification } from '@/services/notification.api';
import { INotification } from '@/Interfaces/INotification';
export const useNotificationStore = defineStore('notification', {
  state: () => ({
    unreadNotificationsCount: 0,
    notifications: [] as INotification[],
  }),
  actions: {
    async fetchUnreadNotificationsCount() {
      try {
        const response = await getUnreadCount();
        this.unreadNotificationsCount = response.data.unread_count;
      } catch (error) {
        console.error('Error fetching unread notifications count:', error);
      }
    },
    async fetchNotifications() {
      try {
        const response = await getAllNotifications();
        this.notifications = response.data;
        await this.fetchUnreadNotificationsCount();
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    },
    /*async dismissNotification(notificationId:any) {
      try {
        await dismissNotification(notificationId);
        this.notifications = this.notifications.filter(
          (notification:any) => notification.id !== notificationId
        );
        await this.fetchUnreadNotificationsCount();
      } catch (error) {
        console.error('Error dismissing notification:', error);
      }
    },*/
    async dismissNotification(notificationId: number) {
        try {
          const response = await dismissNotification(notificationId);
          const updatedNotification = response.data.notification;
          const notification = this.notifications.find(
            (notification) => notification.id === notificationId
          );
          if (notification) {
            notification.read_at = updatedNotification.read_at;
          }
          this.notifications = this.notifications.filter(
            (notification) => notification.id !== notificationId
          );
          await this.fetchUnreadNotificationsCount();
        } catch (error) {
          console.error('Error dismissing notification:', error);
        }
      },
    incrementUnreadNotificationsCount() {
      this.unreadNotificationsCount++;
    },
    decrementUnreadNotificationsCount() {
      if (this.unreadNotificationsCount > 0) {
        this.unreadNotificationsCount--;
      }
    },
  },
});