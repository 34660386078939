
import { defineComponent, defineProps, computed ,onMounted, ref, inject, watch  } from "vue";
import { useRouter } from "vue-router";
import { MoleculNavbarItemsProp } from "@/Interfaces";
import Button from "@/components/Molecules/MoleculButton.vue";
import { useNotificationStore } from "@/Stores/notification";
import { useAuthStore } from "@/Stores/auth";
import axios from "axios";
import { store } from "@/store";
import { useToastStore } from "@/Stores/toast";
import AvatarAtom from "@/components/Atoms/AtomAvatar.vue";
import { Size } from "@/Interfaces"; 
import Echo from "laravel-echo";
import { INotification } from "@/Interfaces/INotification";
import { sendNotification, getAllNotifications, getLatestNotifications, getUnreadCount, dismissNotification } from '@/services/notification.api';
import { profile} from '@/services/users.api';
import {logout} from "@/services/authService.api";
import LocaleSwitcher from "@/components/Organisms/LocaleSwitcher.vue";


export default defineComponent({
  name: "MoleculNavbar",
  components: {
  Button,
 AvatarAtom,
 LocaleSwitcher
  },
  data() {
    return {
      profileMenuOpen: false,
      notifications: [] as INotification[],
      selectedNotification: null,
      latestNotifications: [],
      notificationPanelOpen: false,
      notificationCount: 0,
      unreadNotificationsCount: 0,

    };
  },
  props: {
    items: {
      type: Array,
      required: false,
    },
    className: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const authStore = useAuthStore();
    const notificationStore = useNotificationStore();
    const unreadNotificationsCount = computed(() => notificationStore.unreadNotificationsCount);
    const router = useRouter();
    const user = computed(() => authStore.user);
  
    let currentUser = computed(() => authStore.user);
    const handleProfilIconClick = () => {
 
    const toastMessage = `Nom: ${user.value}`;
 
};
    const isLoggedIn = computed(() => {
  return authStore.user?.email !== null; 
});
    const logout = async () => {
      try {
        authStore.logout(); 
        router.push({ name: 'login' }); 
      } catch (error) {
        console.error('Logout error:', error);
      }
    };
    onMounted(() => {
      notificationStore.fetchUnreadNotificationsCount();
      const win = window as any;
      win.Echo.channel('notifications')
        .listen('NewNotification', (event:any) => {
          notificationStore.incrementUnreadNotificationsCount();
        })
        .listen('NotificationUpdated', (event:any) => {
          notificationStore.fetchUnreadNotificationsCount();
        });
    });


    return {
      Size,
      logout,
      currentUser,
      handleProfilIconClick,
      isLoggedIn,    
 user: computed(() => authStore.user),
    };

  },
  methods: {
    extractTitle(data: any) {
      let message = JSON.parse(data).message;
      let titleEndIndex = message.indexOf(":");
      return message.substring(0, titleEndIndex);
    },
    extractMessage(notificationData: any) {
      try {
        const data = JSON.parse(notificationData);
        return data.message;
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return notificationData;
      }
    },
    parseMessage(fullMessage: any) {
      return fullMessage.split(" ")[0];
    },
    parseTime(fullMessage: any) {
      return fullMessage.split(" ")[1];
    },
  
    extractTime(notificationData: any) {
      const parts = notificationData.split(" ");
      const timeWithSeconds = parts[parts.length - 1];
      return timeWithSeconds.substring(0, 5);
    },
  
    async dismissNotification(notificationId: number) {
      try {
        const response = await dismissNotification(notificationId);

this.unreadNotificationsCount = response.data.unread_count;
        
        const notification = this.notifications.find(
          (n: INotification) => n.id === notificationId
        );
        if (notification) {
          notification.read_at = new Date().toISOString();
        }
        this.fetchUnreadNotificationsCount();
      } catch (error) {
        console.error("Error dismissing notification:", error);
      }
    },

    toggleProfileMenu() {
      this.profileMenuOpen = !this.profileMenuOpen;
    },
    closePanel() {
      this.notificationPanelOpen = false;
    },

    navigateToProfile() {
    profile()
      .then(response => {
        this.$router.push("/profile");
      })
      .catch(error => {
        console.error('Error navigating to profile:', error);
      });
  },
  async handleBellIconClick() {
      this.notificationPanelOpen = !this.notificationPanelOpen;

      if (this.notificationPanelOpen) {
        try {
     
          getLatestNotifications()
          .then((response) => {
            this.notifications = response.data;
          });
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
        this.fetchUnreadNotificationsCount();
      }
    },
    fetchLatestNotifications() {
      getLatestNotifications()
        .then((response) => {
          this.latestNotifications = response.data;
        })
        .catch((error) => {
          console.error("Error fetching latest notifications:", error);
        });
    },
    fetchUnreadNotificationsCount() {
  getUnreadCount()
        .then((response) => {
          this.unreadNotificationsCount = response.data.unread_count;
        })
        .catch((error) => {
          console.error("Error fetching unread notifications count:", error);
        });
    },
  },
  mounted() {
    this.fetchUnreadNotificationsCount();
    const win = window as any;
    win.Echo.channel('notifications')
      .listen('NewNotification', (event: any) => {
        this.notifications.push(event.notification);
        this.unreadNotificationsCount++;
        this.fetchUnreadNotificationsCount();
      })
      .listen('NotificationUpdated', (event: any) => {
        const updatedNotification = event.notification;
        const notification = this.notifications.find((n: INotification) => n.id === updatedNotification.id);
        if (notification) {
          notification.read_at = updatedNotification.read_at;
        }
        this.unreadNotificationsCount = this.notifications.filter(n => !n.read_at).length;
        this.fetchUnreadNotificationsCount();
      });
  },
});
