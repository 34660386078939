
import { defineComponent, computed, PropType } from "vue";

export default defineComponent({
  name: "AtomText",
  props: {
    message: {
      type: String,
      // default:
      //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    isP: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
    },
    type: {
      type: String,
      default: "",
    },
    underline: {
      type: Boolean,
      default: false,
    },
    lineThrough: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const computedClassNames = computed(() => {
      const classNames = ["txt"];
      if (props.type) {
        classNames.push(props.type);
      }
      if (props.className) {
        classNames.push(props.className);
      }
      if (props.underline) {
        classNames.push("underline");
      }
      if (props.lineThrough) {
        classNames.push("linethrough");
      }
      return classNames.join(" ");
    });

    return {
      computedClassNames,
    };
  },
});
