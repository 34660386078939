
import axiosClient from "@/Stores/axios";
import { ref, onMounted, computed } from "vue";
import Text from "@/components/Atoms/AtomText.vue";
import { useAuthStore } from "@/Stores/auth";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import {profile} from "@/services/users.api";

export default {
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const activeId = ref<number>(1);
    const userRole = ref<string[]>([]);
      const { t } = useI18n();
    const rows = computed(() => [
      { id: 1, label: t('dashboard'), icon: "fa-solid fa-gauge", link: "/dashboard" },
      { id: 2, label: t('users'), icon: "fa-solid fa-user", link: "/users" },
      {id: 3,label: t('archive'),icon: "fa-solid fa-archive",link: "/archive-user",},
      { id: 4, label: t('postes'), icon: "fa-solid fa-newspaper", link: "/postes",},
      { id: 5, label: t('roles'), icon: "fa-solid fa-users-cog", link: "/roles" },
      { id: 6, label: t('accesses'), icon: "fa-solid fa-key", link: "/accesses" },
      { id: 7, label: t('agenda'), icon: "fa-solid fa-calendar-alt", link: "/agenda" },
      { id: 8, label: t('notifications'), icon:"fa-solid fa-bell", link: "/notifications" },
      { id: 9, label: t('templates'), icon: "fa-solid fa-clipboard-list", link: "/evaluationform" },
      { id: 10, label: t('evaluationResponses'), icon: "fa-solid fa-clipboard-check", link: "/filledform" }
    ]);







    const user = authStore.user;
    const thumbnailsPath = process.env.VUE_APP_THUMBNAILS_PATH;

    onMounted(() => {
      const storedActiveId = localStorage.getItem("activeId");
      if (storedActiveId) {
        activeId.value = parseInt(storedActiveId);
      }
      getRole();
    });

    const isAdmin = computed(() => {
      return userRole.value.includes('administrateur')
    });

    const changeActiveElement = (row: number, link: string) => {
      activeId.value = row;
      router.push(link);
      localStorage.setItem("activeId", String(row));
    };

    const getRole = async () => {
      try {
        const profileResponse = await profile();
        userRole.value = [profileResponse.data.role];
      } catch (error) {
        console.error("Une erreur s'est produite :", error);
      }
    };


const filteredRows = computed(() => {
      if (Array.isArray(userRole.value) && userRole.value.length > 0) {
        const userRoleObject = userRole.value[0] as unknown as { role: string };
        const userRoleName = userRoleObject.role;
        if (userRoleName === "administrateur") {
          return rows.value;
        } else {
          return rows.value.filter((row: { label: string }) => row.label ===  t('users'));
        }
      } else {
        return [];
      }
    });





    return {
      activeId,
      filteredRows,
      user,
      thumbnailsPath,
      changeActiveElement,
      getRole,
      isAdmin
    };
  },
  components: {
    Text,
  },
};
