import "./bootstrap";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/styles/main.css";
import "./assets/styles/main.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import ToastifyPlugin from "vue3-toastify";
import ApexCharts from "vue3-apexcharts";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createI18n } from "vue-i18n";

interface LocaleMessages {
    [key: string]: Record<string, any>;
}
  async function loadLocaleMessages(): Promise<LocaleMessages> {
    const locales = ['en', 'fr', 'ar'];
    const messages: LocaleMessages = {};
    for (const locale of locales) {
      messages[locale] = (await import(`./locales/${locale}.json`)).default;
    }
    return messages;
  }

loadLocaleMessages().then(messages => {
    const i18n = createI18n({
      legacy: false,
      locale: 'en',
      fallbackLocale: 'en',
      messages,
    });
    library.add(fas);
    library.add(far);
const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("ApexChart", ApexCharts);
app.use(router);
app.use(i18n);
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(ToastifyPlugin);
app.mount("#app");
});
