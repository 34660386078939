import { RouteRecordRaw } from "vue-router";
const addAccess: RouteRecordRaw = {
  path: "/add-access",
  name: "add-access",
  component: () => import("../views/AddAccess.vue"),
};

const editAccess: RouteRecordRaw = {
  path: "/edit-access/:id",
  name: "edit-access",
  component: () => import("../views/AddAccess.vue"),
};

// Exportation des deux routes
export { addAccess, editAccess };