import axios from "axios";
import { useAuthStore } from "@/Stores/auth";

//axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
  axiosClient.interceptors.request.use(config => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    
    return config;
  }, error => {
    return Promise.reject(error);
  });
export default axiosClient;

