import { RouteRecordRaw } from "vue-router";
const addRole: RouteRecordRaw = {
  path: "/add-role",
  name: "add-role",
  component: () => import("../views/AddRole.vue"),
};

const editRole: RouteRecordRaw = {
  path: "/edit-role/:id",
  name: "edit-role",
  component: () => import("../views/AddRole.vue"),
  props: true
};

// Exportation des deux routes
export { addRole, editRole };
