<template>
    <div class="locale-switcher">
      <select v-model="selectedLocale" class="locale-select">
        <option v-for="locale in supportedLocales" :key="locale" :value="locale">
          {{ locale.toUpperCase() }}
        </option>
      </select>
    </div>
  </template>
  <script>
  import { ref, watch } from "vue";
  import { useI18n } from "vue-i18n";
  export default {
    name: "LocaleSwitcher",
    setup() {
      const { locale } = useI18n();
      const supportedLocales = ["en", "fr", "ar"];
      const selectedLocale = ref(locale.value);
      watch(selectedLocale, (newLocale, oldLocale) => {
        locale.value = newLocale;
      });
      return {
        selectedLocale,
        supportedLocales,
      };
    },
  };
  </script>
  <style scoped>
  .locale-switcher {
    display: flex;
    align-items: center;
  }
  .locale-select {
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    padding: 5px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .locale-select:focus {
    outline: none;
  }
  .locale-select option {
    font-size: 16px;
  }
  </style>