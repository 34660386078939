import { defineStore } from "pinia";
import router from "../router/index";
import { User } from "@/Interfaces/IUser";
import 'vue3-toastify/dist/index.css';
import axios from 'axios';
import { useToastStore } from "@/Stores/toast";
import {login, logout} from "@/services/authService.api";


axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "access_token"
)}`;

interface AuthState {
  user: User | null,
  access_token: string| null ,
  refresh_token:  string| null,
  expires_in: string | null,
}
export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    user: null,
    access_token: null,
    refresh_token: null,
    expires_in: null,
  }),
  getters: {
    isAuthenticated: (state) => state.access_token !== null,
   // user: (state) => state.user,
    //access_token: (state) => state.access_token,
    //refresh_token: (state) => state.refresh_token,
   // expires_in: (state) => state.expires_in,
   isNotAuthenticated: (state) => state.access_token == null,
  },

  actions: {
    setUser(user: User) {
      this.user = user;
    },
    setToken(token: string){
      this.access_token = token
    },
  
   renderToast(toastType: string, toastMessage: string) {
      const toastStore = useToastStore();
      toastStore.renderToast({ toastType: toastType, toastMessage: toastMessage });
    }, 
    async authUser(userData: { 
      user: User,
      access_token: string,
      refresh_token: string,
      expires_in: string 
  }) {


      if (userData.user) {
          try {
              this.$patch({
                  user: userData.user,
                  access_token: userData.access_token,
                  refresh_token: userData.refresh_token,
                  expires_in: userData.expires_in
              });
              this.setToken(userData.access_token)
              console.log("user",userData.user);
              console.log("access_token",userData.access_token);
              console.log("refresh_token", userData.refresh_token);
              console.log("expires_in", userData.expires_in);
              localStorage.setItem("user", JSON.stringify(userData.user));
              localStorage.setItem("access_token", userData.access_token);
              localStorage.setItem("refresh_token", userData.refresh_token);
              localStorage.setItem("expires_in", userData.expires_in);
             // document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; HttpOnly; SameSite=Strict; Secure";
             document.cookie = `access_token=${userData.access_token}; HttpOnly; SameSite=Strict; Secure`;
             router.push('/dashboard');
          } catch (error) {
              console.error('Erreur lors de l\'authentification :', error);
          }
        } else {
         useAuthStore().renderToast("error", "Données utilisateur non valides");
          console.error('Données utilisateur non valides :', userData);
        }
  },
  
    clearAuthData() {
      this.$patch({ user: null });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("expires_in");
      document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; HttpOnly; SameSite=Strict; Secure";
    },
    logout() {
      this.clearAuthData(); 

   logout()
        .then(async () => { 
          await this.$patch({ user: null, access_token: null, refresh_token: null, expires_in: null });
          document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; HttpOnly; SameSite=Strict; Secure";
          router.push("/login");
        })
        .catch(error => {
          console.error('Logout error:', error);
        });
    },
    
   

    async login(email : string, password : string) {
      try {
        const data ={
          email: email,
          password: password
        }
        const response = await login (data);
    
        const { user, access_token, refresh_token, expires_in } = response.data;
      
        await this.authUser({
            user: user,
            access_token: access_token,
            refresh_token: refresh_token,
            expires_in: expires_in
        });

        console.log('Utilisateur connecté :', user);
          await this.setUser(user);
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('refresh_token', refresh_token);
        document.cookie = `access_token=${access_token}; HttpOnly; Secure; SameSite=Lax`;
      } catch (error) {
        console.error('Erreur lors de la connexion :', error);
        throw error;
      }
    },
    async refreshAccessToken() {
      // Implement logic to refresh access token using refresh token (stored in HttpOnly cookie)
      // ...
    },

    async checkAndUpdateAuth() {
      const now = Date.now();

      const expiresIn = this.expires_in;
      const expiresInString = expiresIn?.toString() || "0";
      if (isNaN(parseInt(expiresInString))) {
        console.error("Format d'expiration invalide:", expiresInString);
        return;
      }
    
      const expirationTime = now + parseInt(expiresInString) * 1000;
    
      if (now >= expirationTime) {
        console.log("Jeton d'accès expiré, actualisation requise");
        await this.refreshAccessToken();
      }


      const refreshToken = this.getRefreshTokenFromCookie();
      if (!refreshToken) {
  
        console.log("Jeton d'actualisation introuvable, déconnexion de l'utilisateur");
        this.logout();
      }
      console.log("Utilisateur authentifié, jetons valides");
    },
    getRefreshTokenFromCookie() {
   
      const cookieString = document.cookie;
    

      const cookies = cookieString.split(';');
    
    
      for (const cookie of cookies) {
        const [key, value] = cookie.trim().split('=');
    
        if (key === 'refresh_token') {
          return value; 
        }
      }
    

      return null;
    },
   getAccessToken() {
      const cookies = document.cookie.split(';');
      for (const cookie of cookies) {
        const [key, value] = cookie.trim().split('=');
        if (key === 'access_token') {
          return value;
        }
      }
      return null;
    }
    
  },
 // persist: true
});
 
