window._ = require("lodash");
try {
  require("bootstrap");
// eslint-disable-next-line no-empty
} catch (e) {}
window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
window.Pusher = Pusher;
window.Pusher = require("pusher-js");
window.Echo = new Echo({
  broadcaster: "pusher",
  app_id :"1775196",
key :"df923ac5ad42868df568",
secret : "f5cabf8be678e12c5bfd",
cluster : "mt1",
  forceTLS: false,
  wsHost: window.location.hostname,
  wsPort: 6001,
});
const pusher = new Pusher('1775196', {
key :"df923ac5ad42868df568",
secret : "f5cabf8be678e12c5bfd",
cluster : "mt1",
});
const channel = pusher.subscribe('notifications');
channel.bind('App\\Events\\NewNotificationEvent', function(data) {
    console.log('Nouvelle notification reçue:', data.message);
});